<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2" style="height: 62px; width: 100%">
          <h1 class="text-h4">{{ $lang.routes.translations }}</h1>
          <div class="d-inline-flex align-center">
            <v-text-field
              v-model="search"
              class="pl-1"
              outlined
              clearable
              hide-details
              dense
              :label="$lang.labels.searchByName"
              data-cy="search-by-name"
            ></v-text-field>
            <v-btn
              text
              light
              class="ml-2"
              color="primary"
              @click="$emit('fetchTranslations', { options: options, search: search.length > 1 ? search : '', resetPage: true })"
            >
              <v-icon
                dense
                small
              >
                mdi-refresh
              </v-icon>
            </v-btn>
            <v-btn
              v-if="roles.includes('TEMPLATE_CREATOR') || roles.includes('SUPER_USER')"
              color="accent"
              class="color-accent-text mr-1"
              :to="{ name: 'translationCreate', params: { lang: $lang.current_lang } }"
            >{{ $lang.actions.create }}
            </v-btn>
          </div>
        </div>
        <v-divider class="mt-5"></v-divider>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.language="{ item }">
        <div>{{ $lang.labels[item.language] }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex">
          <v-btn color="primary" class="mx-0 button-default-width" :to="{ name: 'translationEdit', params: { id: item.id } }">{{ $lang.actions.edit }}</v-btn>
          <v-btn
            v-if="roles.includes('TEMPLATE_CREATOR') || roles.includes('SUPER_USER')"
            color="accent"
            class="ml-1 button-default-width color-accent-text"
            @click="copyCreateResource(item, 'translationCreate', $router, '', $options.filters.formatDateTime(new Date()))"
          >{{ $lang.actions.copyCreate }}</v-btn>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
import { copyCreateResource } from '@/utils/helpers'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      roles: [],
      lock: true,
      showTypeSelector: false,
      activeOnly: true,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.language, value: 'language' },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ]
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchTranslations', { options: this.options, search: this.search.length > 1 ? this.search : '' })
        this.savePreFill()
      },
      deep: true
    },
    search: {
      handler (val) {
        if (!this.lock && val && val.length > 1) this.$emit('fetchTranslations', { options: this.options, search: val, resetPage: true })
        if (!this.lock && val === null || val === '') this.$emit('fetchTranslations', { options: this.options, search: '', resetPage: true })
        this.savePreFill()
        this.options.page = 1
      }
    },
    activeOnly: {
      handler (val) {
        if (!this.lock) this.$emit('activeOnly', { activeOnly: val, options: { options: this.options, search: this.search.length > 1 ? this.search : '', resetPage: true } })
        this.savePreFill()
        this.options.page = 1
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchTranslations', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
      this.options.page = 1
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(2, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn' })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(2, 0, { text: this.$lang.labels.createdOn, value: 'createdOn' })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.translationList) {
        this.search = preFill.translationList.search
        this.activeOnly = preFill.translationList.activeOnly
        this.options = preFill.translationList.options

        this.$emit('activeOnly', { activeOnly: this.activeOnly, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true } })
      } else {
        this.$emit('fetchTranslations', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
      }
    } else {
      this.$emit('fetchTranslations', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
    }
    setTimeout(() => this.lock = false, 100)
    this.options.page = 1
  },
  methods: {
    copyCreateResource,
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.translationList) preFill.translationList = {
          search: '',
          activeOnly: true,
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.translationList.search = this.search
        preFill.translationList.activeOnly = this.activeOnly
        preFill.translationList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    }
  }
}
</script>
